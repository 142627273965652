<template>
  <div class="container">
    <h1 class="mt-2">Registration complete</h1>
    <p>Thank you for registering! To pair with a new NoticeHome
      <router-link to="/pair">click here</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'RegistrationComplete.vue'
}
</script>

<style scoped>

</style>
