import router from '../router'
import axios from 'axios'
import { parseJWT } from '@/helpers'

const userId = JSON.parse(localStorage.getItem('userId'))
const email = JSON.parse(localStorage.getItem('email'))
const accessToken = JSON.parse(localStorage.getItem('accessToken'))
const refreshToken = JSON.parse(localStorage.getItem('refreshToken'))

const state = {
  userId: userId,
  accessToken: accessToken,
  refreshToken: refreshToken,
  email: email
}

const actions = {
  async refresh ({ dispatch, commit }, redirectOnFail) {
    // Authorise a user when the browser already has a token/userId in localstorage but nothing in store
    // This allows the server to reject an invalid token. If hardCheck, redirects to /login
    return new Promise((resolve, reject) => {
      const refreshToken = JSON.parse(localStorage.getItem('refreshToken'))
      const path = '/api/v1/auth/access-token-refresh'
      axios.post(path, { refresh_token: refreshToken })
        .then((response) => {
          // If the server responds with the access_token, our token is valid
          const accessToken = response.data.access_token
          if (accessToken) {
            // Update the token in the axios default header
            const jwt = parseJWT(accessToken)
            localStorage.setItem('accessToken', JSON.stringify(accessToken))
            localStorage.setItem('accessTokenExp', jwt.exp)
            axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken
            commit('refreshSuccess', response)
            console.log('Got new access token')
            resolve()
          } else {
            dispatch('logout')
            if (redirectOnFail) {
              router.push('/login').then(res => {
                // eslint-disable-next-line
                console.log(res.message)
              })
            }
            reject(new Error('Failed to get token'))
          }
        })
        .catch(function (error) {
          dispatch('logout')
          if (error.response && error.response.status === 401) {
            // eslint-disable-next-line
            console.log('re-auth failed, redirecting to /login')
            dispatch('alert/error', error, { root: true })
            localStorage.clear()
            if (redirectOnFail) {
              router.push('/login').then(res => {
                // eslint-disable-next-line
                console.log(res.message)
              })
            }
          }
          reject(new Error('Failed to get token'))
        })
    })
  },

  logout ({ commit }) {
    localStorage.clear()
    commit('clearStore')
    commit('scheduling/clearStore', null, { root: true })
    commit('screens/clearStore', null, { root: true })
    commit('images/clearStore', null, { root: true })
    commit('events/clearStore', null, { root: true })
    router.push('/login').catch(error => {
      // eslint-disable-next-line
      console.log(error)
    })
  }

}

const mutations = {
  loginSuccess (state, response) {
    const jwt = parseJWT(response.data.access_token)
    // Add data to local storage
    localStorage.setItem('accessToken', JSON.stringify(response.data.access_token))
    localStorage.setItem('refreshToken', JSON.stringify(response.data.refresh_token))
    localStorage.setItem('accessTokenExp', jwt.exp)
    localStorage.setItem('email', JSON.stringify(response.data.email))
    localStorage.setItem('userId', JSON.stringify(response.data.user_id))

    // Put the token in the axios default header
    axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.access_token

    // Save data to the store
    state.email = response.data.email
    state.accessToken = response.data.access_token
  },
  loginFailure
  (state) {
    state.email = null
  },
  refreshSuccess (state, response) {
    state.accessToken = response.data.access_token
  },
  clearStore (state) {
    Object.assign(state, getDefaultState())
  }
}

export const authentication = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}

/**
 *  Resets the state
 */
const getDefaultState = () => {
  return {
    userId: null,
    email: null,
    accessToken: null,
    refreshToken: null
  }
}

// Intercept all errors and redirect to /login if there's a 401 response
// axios.interceptors.response.use((response) => {
//     return response
// }, function (error) {
//     let originalRequest = error.config
//     if (error.response.status === 401 && !originalRequest._retry) { // if the error is 401 and hasn't already been retried
//         console.log("Logging out due to 401 from server")
//         originalRequest._retry = true // now it can be retried
//         store.dispatch('logout').then(r => {
//             console.log(r.message)
//         })
//     }
//     if (error.response.status === 404 && !originalRequest._retry) {
//         originalRequest._retry = true
//         window.location.href = '/'
//         return
//     }
//     // Do something with response error
//     return Promise.reject(error)
// })
