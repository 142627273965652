<template>

  <div id="container" class="container-fluid">
    <div class="fixed-top bg-dark">
      <div class="col justify-content-center">
        <img id="topLogo" class="card-img-top px-5 py-2 d-md-none" src="~@/assets/logo-full.png" alt="logo">
      </div>
    </div>
    <div class="col fixed-bottom bg-dark d-md-none">
      <div class="row">
        <p class="card-text text-white px-5 py-3">Convert a standard television into a live noticeboard to assist those living alone while vulnerable.</p>
      </div>
      <div class="row pb-3">
        <div class="col align-self-center">
          <router-link to="/shop">
            <a class="btn btn-lg mx-2 btn-outline-primary">Learn more</a>
          </router-link>
        </div>
        <div class="col col-auto align-content-end">
          <div v-if="email">
            <router-link to="/login">
              <a class="btn btn-lg mx-2 btn-outline-primary">Dashboard</a>
            </router-link>
          </div>
          <div v-else>
            <router-link to="/login">
              <a class="btn btn-lg mx-2 btn-outline-primary">Log in</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>

  <!-- desktop view -->
      <div id="desktopContainer" class="d-none d-md-grid">
          <div id="infoCard" class="card bg-dark text-white">
            <div class="card-body">
              <img class="card-img-top" src="~@/assets/logo-full.png" alt="logo">
              <p class="card-text mt-4">Convert a standard television into a live noticeboard to assist those living alone while vulnerable.</p>
              <div class="row pb-3">
                <div class="col align-self-center">
                  <router-link to="/shop">
                    <a class="btn btn-outline-primary">Learn more</a>
                  </router-link>
                </div>
                <div class="col col-auto align-content-end">
                  <div v-if="email">
                    <router-link to="/login">
                      <a class="btn btn-outline-primary">Dashboard</a>
                    </router-link>
                  </div>
                  <div v-else>
                    <router-link to="/login">
                      <a class="btn btn-outline-primary">Log in</a>
                    </router-link>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import store from '@/store'

export default {
  name: 'Splash',
  components: {},
  computed: {
    accessToken () {
      return store.state.authentication.accessToken
    },
    email () {
      return store.state.authentication.email
    }
  }
}

</script>

<style scoped>

</style>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 500px) {
  #container {
    background: url(~@/assets/home-bg-1.png) no-repeat fixed center;
    background-size: cover;
    height: 1000px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
}

@media only screen and (min-width: 500px) {
  #container {
    background: url(~@/assets/home-bg-1.png) no-repeat fixed center;
    background-size: cover;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
}

#desktopContainer {
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

#infoCard {
  grid-column: 3 / 6;
  grid-row: 2;
}

</style>
