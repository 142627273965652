<template>
  <div class="modal fade" tabindex="-1" id="imageModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  @click="resetImageUploadForm"
                  aria-label="Close"></button>
        </div>

        <ul class="nav nav-tabs nav-fill mb-3">
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{ active: showPublicImages }"
               @click="setShowPublicImages" href="#">Default
              Images</a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{ active: showUserImages }"
               @click="setShowUserImages" href="#">
              Your Images
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link"
               v-bind:class="{ active: showUploadImage }"
               @click="setShowUploadNewImage" href="#">
              Upload New
            </a>
          </li>
        </ul>

        <div class="container">

          <div v-if="showPublicImages" class="card-group">
            <div v-for="(image, uuid) in publicForegroundImages" :key="uuid" class="card px-1">
                <img class="card-img-top"
                     :style="{ cursor: 'pointer', width: '200px'}"
                     v-bind:src="image"
                     v-on:click="$emit('selectImage', uuid)"
                     alt="Image"
                     data-bs-dismiss="modal">
            </div>
          </div>

          <div v-if="showUserImages" class="card-group">
          <div v-for="(image, uuid) in userForegroundImages" :key="uuid">
            <div v-if="!(uuid in publicForegroundImages)" class="card px-1">
              <img class="card-img-top"
                   :style="{ cursor: 'pointer', width: '200px'}"
                   v-bind:src="image"
                   v-on:click="$emit('selectImage', uuid)"
                   alt="Image"
                   data-bs-dismiss="modal">
            </div>
          </div>
        </div>

          <div v-if="showUploadImage" class="card-group">
            <div v-if="!uploadComplete" class="input-group mb-2" v-bind:class="{ 'is-invalid': uploadIsInvalid}">
              <input type="file"
                     accept="image/jpeg"
                     class="form-control"
                     v-bind:class="{ 'is-invalid': uploadIsInvalid}"
                     @change=setImage aria-label="Upload image"
                     aria-describedby="imageUploadFeedback">
              <button class="btn btn-outline-secondary" @click="upload" type="button">Upload</button>
            </div>
            <div id="imageUploadFeedback" class="invalid-feedback">
              Please select a file to upload.
            </div>
            <div v-if="uploading" class="spinner-border" role="status">
              <span class="visually-hidden">Uploading...</span>
            </div>
            <img v-if="uploadPreview" v-bind:src="uploadPreview" class="rounded mx-auto d-block mb-2" width="200" alt="Your Image"/>
          </div>
          <div class="alert alert-success" v-if="uploadComplete">
            Upload complete!
            <button type="button"
                    class="btn btn-success"
                    @click="resetImageUploadForm"
                    data-bs-dismiss="modal">
              Close
            </button>
          </div>

          <div class="alert alert-warning" v-if="uploadFailed">
            Upload failed
            <button type="button"
                    class="btn btn-warning"
                    @click="upload">
              Retry
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'ImageDialogue',
  emits: ['selectImage'],

  data: () => ({
    title: undefined,
    uploadImage: null,
    uploadInputValue: undefined, // manipulated to remove filename from image input field
    uploadPreview: null,
    uploadIsInvalid: false,
    uploadComplete: false,
    showPublicImages: true,
    showUserImages: false,
    showUploadImage: false,
    uploading: false,
    uploadFailed: false
  }),

  methods: {
    upload () {
      // If there is no image preview, invalidate the upload
      this.uploadFailed = false
      if (this.uploadImage == null) {
        this.uploadIsInvalid = true
        return
      }
      this.uploadIsInvalid = false
      this.uploading = true
      this.uploadPreview = null
      // upload image as a form
      const formData = new FormData()
      formData.append('image', this.uploadImage, this.uploadImage.name)
      this.$store.dispatch('images/uploadUserImage', formData).then(res => {
        this.uploading = false
        this.uploadComplete = true
        this.$emit('selectImage', res.data.uuid)
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        this.uploading = false
        this.uploadFailed = true
      })
    },
    setImage (e) {
      // When uploading, set the image as soon as the user selects it, so it can be displayed
      this.uploadImage = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.uploadImage)
      reader.onload = e => {
        this.uploadPreview = e.target.result
      }
      this.uploadIsInvalid = false
    },
    resetImageUploadForm () {
      this.uploadImage = null
      this.uploadPreview = null
      this.uploading = false
      this.uploadIsInvalid = false
      this.uploadComplete = false
    },
    setShowPublicImages () {
      this.showPublicImages = true
      this.showUserImages = false
      this.showUploadImage = false
    },
    setShowUserImages () {
      this.showPublicImages = false
      this.showUserImages = true
      this.showUploadImage = false
    },
    setShowUploadNewImage () {
      this.showPublicImages = false
      this.showUserImages = false
      this.showUploadImage = true
    }
  },
  computed: {
    userForegroundImages () {
      return this.$store.state.images.userForegroundImages
    },
    publicForegroundImages () {
      return this.$store.state.images.publicForegroundImages
    }
  }
}
</script>
