/**
 * Turns an object and all its keys to camel case
 * @param o
 * @returns {{}|*}
 */
export function keysToCamel (o) {
  if (isObject(o)) {
    const n = {}

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(o[k])
      })

    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i)
    })
  }

  return o
}

/**
 * Turns an object and all its keys from camel case to snake case
 * @param o
 * @returns {{}|*}
 */
export function keysToSnake (o) {
  if (isObject(o)) {
    const n = {}

    Object.keys(o)
      .forEach((k) => {
        n[toSnake(k)] = keysToSnake(o[k])
      })

    return n
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i)
    })
  }

  return o
}

export function toSnake (s) {
  return s.replace(/([A-Z])/g, '_$1').toLowerCase()
}

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function'
}
const isArray = function (a) {
  return Array.isArray(a)
}

function toCamel (s) {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}
