<template>
  <div class="container" style="max-width: 576px">
    <h2>Login</h2>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="email">Email Address</label>
        <input id="email" type="text" v-model="email" name="email" class="form-control"
               :class="{ 'is-invalid': submitted && !email }"/>
        <div v-if="submitted && !email" class="invalid-feedback">Please enter your email address</div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" type="password" v-model="password" name="password" class="form-control"
               :class="{ 'is-invalid': submitted && !password }"/>
        <div v-if="submitted && !password" class="invalid-feedback">Please enter your password</div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary mt-2" :disabled="loggingIn">
          Login
          <span v-if="loggingIn" class="spinner-border spinner-border-sm" role="status"/>
        </button>
      </div>
      <router-link to="/forgot-password">Forgot your password?</router-link>
    </form>
    <div v-if="showError" class="mx-auto alert alert-danger my-2" role="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
import router from '@/router'

export default {
  data () {
    return {
      email: '',
      password: '',
      submitted: false,
      loggingIn: false,
      showError: false,
      errorMessage: 'Failed'
    }
  },
  methods: {
    handleSubmit () {
      this.loggingIn = true
      this.submitted = true
      this.showError = false
      const { commit } = this.$store
      const path = '/api/v1/auth/login'
      const data = qs.stringify({
        grant_type: 'password',
        username: this.email,
        password: this.password
      })
      axios.post(path, data)
        .then((response) => {
          // eslint-disable-next-line
            console.log(response)
          // If the server responds with a token, our login is valid
          if (response.data.refresh_token) {
            this.loggingIn = false
            commit('authentication/loginSuccess', response)
            let nextPage = '/dashboard'
            if (this.$route.query.nextPage) {
              nextPage = this.$route.query.nextPage
            }
            router.push(nextPage).catch((error) => {
              // eslint-disable-next-line
                console.error(error);
            })
          } else {
            this.showError = true
            this.loggingIn = false
            this.errorMessage = 'Login failed'
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            this.loggingIn = false
            this.showError = true
            this.errorMessage = 'Incorrect email or password'
          } else {
            this.showError = true
            this.loggingIn = false
            this.errorMessage = 'Login failed'
          }
        })
    }
  }
}
</script>
