<template>
  <div class="container-sm">

    <div class="container bg-light p-4 mt-3">
      <h1>Your Images</h1>
      <div class="card-group">

        <div v-for="(image, uuid) in userForegroundImages" :key="uuid">

          <img class="card-img img-fluid img-rounded"
               v-bind:src="image"
               v-bind:alt="uuid">
          <div class="card-footer">
            <button @click="deleteImage(uuid)" type="button" class="btn btn-danger">Delete</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'UserImages',
  data: () => ({}),
  methods: {
    deleteImage (imageUuid) {
      this.$store.dispatch('images/deleteImage', imageUuid).then(() => {
      }).catch(() => {
        this.saving = false
        this.saveFail = true
      })
    }
  },
  computed: {
    userForegroundImages () {
      return this.$store.state.images.userForegroundImages
    }
  },
  created () {
    this.$store.dispatch('images/getAllUserImages', true)
  }
}
</script>

<style scoped>

</style>
