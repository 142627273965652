<template>
  <div class="container">
    <div class="row flex-xl-nowrap">
      <main class="col-12 py-md-3">
          <h1 class="text-center">About Us</h1>
        <h2 class="my-3">The First NoticeHome</h2>
        <div class="row">
          <div class="col col-9">

            <p>The idea for the NoticeHome was conceived on the 18th of December 2018. I remember the date because it's
              my
              Granny Joyce's birthday. She’d been living alone with memory problems, after the death of her husband, my
              grandpa and this company's namesake, Ken.</p>

            <p>
              On this day, my family arrived at her house to pick her up for her big birthday meal out. Joyce had been
              looking
              forward to the meal, it was on her calendar, but as we show up on the doorstep it became apparent she had
              forgotten all about it. She was elated for the now-surprise birthday party, but "Oh… I've already eaten"
              she
              said. Great. We laughed it off and took her out anyway, but I started to think there must be some way
              technology
              could help prevent this.

              I’ve inherited my Granny’s knack for forgetting important dates, but I have a phone in my pocket that
              reminds
              me. Sending calendar invites to her seldom-used phone didn’t seem like the best idea, and the tiny
              calendar on
              the wall was being ignored, so I hacked together a plan. I used an old 32-inch TV and a cheap computer to
              load a
              website, refreshing it every few minutes or so. I hosted the website from my house, and I could change the
              website at will. “Go back to bed!” It said at 1am (Joyce had a habit of waking up too early). “Sam is
              picking
              you up at 2pm today!” would make her face light up every time she walked past and read it. For the rest of
              the
              day it would just show a friendly message and maybe a picture of a family member.</p>
            <div class="row align-items-center">
              <div class="col col-1">
                <img id="sam-and-joyce" class="card-img-top rounded float-start" src="~@/assets/sam-and-joyce.png" alt="Sam and Joyce">
              </div>
              <div class="col">
                - Sam
              </div>
            </div>
          </div>

          <div class="col col-3">
            <img id="first-noticehome" class="card-img-top rounded float-start" src="~@/assets/first-noticehome.png" alt="The First NoticeHome">
          </div>
          <div class="row d-flex justify-content-center">
          </div>
        </div>

        <h2>Team</h2>

      </main>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Shop'
}
</script>

<style scoped>
#first-noticehome {
  height: 500px;
  width: auto;
}

#sam-and-joyce {
  height: 60px;
  width: auto;
}
</style>
