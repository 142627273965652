<template>

  <!-- Section 1 -->
  <section id="section1" class="bg-image-section" data-speed="8" data-type="background">
  </section>

  <!-- Section 2 -->
  <section id="section2" data-speed="4" data-type="background">
    <div id="card1" class="card m-auto">
      <div class="card-body">
        <h1 class="card-title text-center">Your messages on a custom schedule</h1>
        <p class="card-text text-center">Show different notices throughout the day to provide helpful reminders.</p>
      </div>
    </div>
  </section>
  <!-- Section 3 -->
  <section id="section3" class="bg-image-section" data-speed="2" data-type="background">
  </section>

  <!-- Section 4 -->
  <section id="section4" class="container">
    <div class="row my-5">
      <div class="col-6 d-flex align-items-center">
        <h2 class="text-center">Update your NoticeHome easily from anywhere</h2>
      </div>
      <div class="col-6">
        <img src="~@/assets/phone.png" class="img-fluid">
      </div>
      <div class="img-fluid px-4 mt-5">
        <img src="~@/assets/home-bg-3.png" class="img-fluid">
      </div>
    </div>
  </section>
  <section id="section5" class="container-sm">
    <div id="descriptionText">
      <div class="row px-5 mt-5">
        <p>Update from your phone at anytime</p>
      </div>
      <div class="row align-items-center px-5 mt-5">
        <p>Upload your own images and create your own displays</p>
      </div>
      <div class="row px-5 mt-5">
        <p>No subscription</p>

      </div>
      <div class="row px-5 mt-5">
        <a :href="purchaseLink" class="btn btn-outline-primary mx-1" type="submit">Buy now</a>
        <p class="text-secondary mt-2">NoticeHome is a small device that plugs into a HDMI port on your TV and connects to Wifi. The TV will then
        display your messages on a schedule throughout the day, as well as "one-off" notifications that can be added
        at any time.</p>

      </div>
    </div>
  </section>
</template>

<script>
import { stripePurchaseLink } from '@/config'

export default {
  name: 'Shop',
  computed: {
    purchaseLink () {
      return stripePurchaseLink
    }
  }
}

</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

#section5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1 0 100px;
}

@media only screen and (max-width: 500px) {
  .bg-image-section {
    height: 800px;
  }

  #section3 {
    height: 800px;
  }

  #section5 {
    height: 600px;
  }
}

@media only screen and (min-width: 500px) {
  .bg-image-section {
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(1, 100vw);
    grid-template-rows: repeat(5, 200px);
  }

  #card1 {
    grid-column: 1;
    grid-row: 3;
  }
}

#section1 {
  background: url(~@/assets/home-bg-2a.png) top center no-repeat;
  background-size: cover;
}

#section3 {
  background: url(~@/assets/home-bg-2b.png) top center no-repeat;
  background-size: cover;
}

</style>
