<template>
  <div class="modal fade" tabindex="-1" id="templateModal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Select a template</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="container">
          <div class="row flex-row row-cols-xl-3">
            <div v-for="(image, uuid) in templatePreviews" :key="uuid"
                 class="col d-flex align-self-center justify-content-center">
              <img v-bind:src="image" alt="Image"
                   class="border rounded"
                   v-on:click="$emit('selectTemplate', uuid)"
                   :style="{ cursor: 'pointer', width: '300px'}"
                   data-bs-dismiss="modal">
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">Close</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'TemplateDialogue',
  emits: ['selectTemplate'],
  computed: {
    templatePreviews () {
      return this.$store.state.images.templatePreviews
    }
  },
  data: () => ({
    images: undefined
  })
}
</script>
