import { createStore } from 'vuex'
import { alert } from './alerts.module'
import { authentication } from '@/store/authentication.module'
import { scheduling } from '@/store/scheduling.module'
import { images } from '@/store/images.module'
import { events } from '@/store/events.module'
import { screens } from '@/store/screens.module'

export default createStore({
  modules: {
    alert,
    authentication,
    scheduling,
    images,
    events,
    screens
  }
})
