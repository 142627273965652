<template>
  <div class="modal fade" tabindex="-1" id="timePickerModal">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body mb-3">
          <div class="form-control-lg">
            <label for="time-picker" class="form-label">Start time</label>
            <input type="time" v-model="startTime" id="time-picker" class="time-input form-control">
            <button class="btn btn-secondary mt-3"
                    data-bs-dismiss="modal"
                    aria-label="Confirm"
                    @click="returnTime">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'TimePickerDialogue',
  props: {
    title: String
  },

  data: () => ({
    startTime: '00:00'
  }),

  methods: {
    returnTime () {
      this.$emit('return-time', this.startTime)
    }
  },
  computed: {}
}
</script>

<style scoped>
.time-input {
  font-size: 32px;
}
</style>
