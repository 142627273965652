<template>
  <div class="modal fade" id="createQuickEventModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">Add reminder</h2>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col">
              <label for="display-text">Message</label>
              <input v-model="eventText" id="display-text" type="text" class="form-control" placeholder="Enter your message">
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="start-time">At:</label>
              <input v-model="startTime" id="start-time" type="time" class="form-control">
            </div>
            <div class="col">
              <label for="start-date"></label>
              <button v-if="today" class="form-control" @click="today = false">Today</button>
              <input v-else v-model="startDate" id="start-date" type="date" class="form-control">
            </div>
            <div class="col col-auto">
              <label for="duration-select">Duration:</label>
              <select v-model="eventDuration" id="duration" class="form-control form-select mb-4" name="duration-select">
                <option value="1h">1 hour</option>
                <option value="3h" selected>3 hours</option>
                <option value="day">Rest of the day</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mx-2">
          <p class="fw-light">For more options, go to
            <a href="#" @click="redirectToEvents" data-bs-dismiss="modal">reminders.</a>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success" :disabled="saving" data-bs-dismiss="modal" @click="postEvent()">
            Create
            <span v-if="saving" class="spinner-border spinner-border-sm" role="status"/>
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { parse } from 'date-fns'

export default {
  name: 'CreateQuickEventModal',
  props: {
    eventUuid: String,
    screenUuid: String
  },
  data () {
    return {
      eventDuration: '3h',
      startTime: null,
      startDate: null,
      eventText: null,
      today: true,
      saving: false,
      saveFail: false
    }
  },
  methods: {
    postEvent () {
      this.saving = true
      this.saveFail = false
      const data = this.parseForm()
      this.$store.dispatch('events/postEvent', data).then(() => {
        this.saving = false
        this.$emit('modified')
        this.$emit('close')
      }).catch(() => {
        this.saving = false
        this.saveFail = true
      })
    },
    parseForm () {
      let date
      if (this.today) {
        date = new Date()
      } else {
        date = new Date(this.startDate)
      }
      const startDateTime = parse(this.startTime, 'HH:mm', date)
      const endDateTime = new Date(startDateTime.valueOf())
      switch (this.eventDuration) {
        case '1h':
          endDateTime.setHours(startDateTime.getHours() + 1)
          break
        case '3h':
          endDateTime.setHours(startDateTime.getHours() + 3)
          break
        case 'day':
          endDateTime.setHours(24)
          endDateTime.setMinutes(60)
          break
      }
      return {
        // Might need to tell the server to accept no image uuid
        screenUuid: this.$props.screenUuid,
        displayText: this.eventText,
        eventStart: startDateTime.toISOString(),
        eventEnd: endDateTime.toISOString()
      }
    },
    redirectToEvents () {
      this.$router.push('events')
    }
  },
  created () {
    this.startDate = new Date().toISOString().substring(0, 10)
    this.startTime = new Date().toISOString().substring(11, 16)
  }
}
</script>

<style scoped>

#start-time {
  text-align: center;
}

</style>
