<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col text-center mt-2">
        <h2>Upcoming Reminders</h2>
      </div>
      <div class="btn btn-secondary" @click="createNewEvent = true">Create new</div>
      <div v-if="createNewEvent" class="col">
        <event-form :new="true" v-on:close="createNewEvent = false" v-on:modified="sortEvents()"></event-form>
      </div>
      <div class="row py-1 my-3 border-left " v-for="date in eventDaysArray" :key="date">
        <div class="row">
          <p class="fs-1 fw-bold">{{ format(new Date(date), 'dd-MM-YYY') }}</p>
        </div>

        <div v-for="uuid in sortedEventsDict[date]" :key="uuid" class="row my-2 py-2 ml-2 border-top">

          <!--          Form -->
          <event-form v-if="uuid === activeEventUuid"
                      :event-uuid="uuid"
                      :new="false"
                      v-on:close="unSelectEvent()"
                      v-on:modified="sortEvents()"></event-form>
          <!--          Event display -->
          <div class="row align-items-center">
            <div class="col">
              <div class="row">
                <p class="fs-6 fw-light">{{ format(events[uuid].eventStart, 'HH:mm') }} - {{ format(events[uuid].eventEnd, 'HH:mm') }}</p>
              </div>
              <div class="row">
              <p class="fs-2 fw-bold">{{ events[uuid]['displayText'] }}</p>
              </div>
            </div>
            <div class="col col-auto">
              <button type="button" @click="selectEvent(uuid)" class="btn btn-secondary justify-content-center align-items-end">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import format from 'date-fns/format'

import EventForm from '@/components/EventForm'

import { getSortedDailyEventsDict, getEventDaysArray } from '@/helpers'

export default {

  data () {
    return {
      sortedEventsDict: null, // A dictionary of dates mapped to arrays of events for each day {1-1-20: [123, 456]}
      eventDaysArray: null,
      activeEventUuid: null,
      createNewEvent: false,
      saving: false,
      saveSuccess: false,
      saveFail: false,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'D J M Y',
        altInput: true,
        dateFormat: 'd-m-Y',
        minDate: new Date(), // no dates in the past
        eventUuid: 'test'
      },
      format: format
    }
  },
  name: 'Events.vue',
  components: { EventForm },
  computed: mapState({
    userId: state => state.authentication.userId,
    events: state => state.events.scheduleEvents,
    screens: state => state.screens.screens
  }),

  methods: {
    selectEvent (eventUuid) {
      this.activeEventUuid = eventUuid
    },
    unSelectEvent () {
      this.activeEventUuid = null
    },
    sortEvents () {
      this.eventDaysArray = getEventDaysArray(this.events)
      this.sortedEventsDict = getSortedDailyEventsDict(this.events)
    }
  },
  created () {
    this.$store.dispatch('screens/getScreens')
    this.$store.dispatch('events/getScheduleEvents').then(() => {
      this.sortEvents()
    })
    this.$store.dispatch('images/getAllUserImages')
  }

}
</script>

<style scoped>

</style>
