import axios from 'axios'
import { keysToCamel, keysToSnake, parseIncomingDateTimes } from '@/helpers'

// import {createDailyEventsDict} from "@/helpers"

const getDefaultState = () => {
  return {
    scheduleEvents: {}
  }
}

const state = getDefaultState()

const actions = {
  async getScheduleEvents ({ commit }) {
    return new Promise((resolve, reject) => {
      const path = '/api/v1/event/'
      axios.get(path)
        .then((res) => {
          let scheduleEvents = res.data
          scheduleEvents = keysToCamel(scheduleEvents)
          if (scheduleEvents.length > 0) {
            for (let scheduleEvent of scheduleEvents) {
              scheduleEvent = parseIncomingDateTimes(scheduleEvent)
              commit('addScheduleEventToStore', scheduleEvent)
            }
          }
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(new Error('Failed to get schedule events'))
        })
    })
  },

  async postEvent ({ commit }, event) {
    return new Promise((resolve, reject) => {
      const path = '/api/v1/event/'
      const payload = keysToSnake(event)
      axios.post(path, payload).then((res) => {
        let scheduleEvent = keysToCamel(res.data)
        scheduleEvent = parseIncomingDateTimes(scheduleEvent)
        commit('addScheduleEventToStore', scheduleEvent)
        resolve(res)
      })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(error)
        })
    })
  },
  async putEvent ({ commit }, event) {
    return new Promise((resolve, reject) => {
      const path = `/api/v1/event/${event.uuid}`
      const payload = keysToSnake(event)
      axios.put(path, payload).then((res) => {
        let scheduleEvent = keysToCamel(res.data)
        scheduleEvent = parseIncomingDateTimes(scheduleEvent)
        commit('editScheduleEvent', scheduleEvent)
        resolve(res)
      })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(error)
        })
    })
  },
  async deleteEvent ({ commit }, eventUuid) {
    return new Promise((resolve, reject) => {
      const path = `/api/v1/event/${eventUuid}`
      axios.delete(path)
        .then(() => {
          commit('removeEvent', eventUuid)
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(new Error('Failed to delete event'))
        })
    })
  }
}

const mutations = {
  addScheduleEventToStore (state, data) {
    state.scheduleEvents[data.uuid] = data
  },
  editScheduleEvent (state, data) {
    state.scheduleEvents[data.uuid] = data
  },
  removeEvent (state, eventUuid) {
    delete state.scheduleEvents[eventUuid]
  },
  clearStore (state) {
    Object.assign(state, getDefaultState())
  }
}

export const events = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}
