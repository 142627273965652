<template>
  <div class="container-sm">
    <form>
      <div class="my-3">
        <label for="codeInput" class="form-label">Device Code</label>
        <input v-model="deviceCode" id="codeInput" aria-describedby="codeInput" type="text" class="form-control"
               :class="{ 'is-invalid': submitted && !deviceCode }">
        <div id="codeHelp" class="form-text" :class="{'invalid-feedback': (submitted && !deviceCode)}">
          Enter the code displayed by your NoticeHome. It should be in the format ABCD-EFGH
        </div>

      </div>
      <div class="my-3">
        <label for="nameInput" class="form-label">Your NoticeHome Name</label>
        <input v-model="deviceName" type="text" class="form-control" id="nameInput" aria-describedby="nameInput"
               :class="{ 'is-invalid': submitted && !deviceName }">
        <div id="nodeHelp" class="form-text" :class="{'invalid-feedback': (submitted && !deviceName)}">
          Enter a name to recognise this NoticeHome (e.g. "Granny's Kitchen")
        </div>
      </div>
    </form>
    <button id="submitBtn"
            @click="pairScreen"
            class="btn mb-3"
            :class="{
      'disabled': pairing || paired || setupComplete,
      'btn-primary': !setupComplete,
      'btn-success': setupComplete}">

      <a v-if="!pairing && !paired && !setupComplete">Submit</a>
      <a v-if="pairing">Pairing </a>
      <a v-if="settingUp">Setting up device </a>
      <a v-if="setupComplete">Complete </a>
      <!--Check marks and loading symbols for pairing -->
      <svg v-if="paired && !setupComplete" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
      </svg>
      <svg v-if="setupComplete" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
        <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
        <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
      </svg>
      <span v-if="pairing || settingUp" class="spinner-border spinner-border-sm" role="status"/>
    </button>
    <br>
    <a v-if="setupComplete" href="/schedule" class="alert-link">Click here to start creating a schedule</a>

    <div v-if="failed" class="alert alert-danger">
      <strong>Failed to pair device.</strong> {{ failureMessage }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import { baseURLWS } from '@/config'

export default {
  name: 'Pair',
  data () {
    return {
      deviceCode: null,
      deviceName: null,
      submitted: false,
      pairing: false,
      paired: false,
      settingUp: false,
      setupComplete: false,
      failed: false,
      failureMessage: 'Error connecting to Kenban server. Check your connection'
    }
  },
  methods: {
    async pairScreen () {
      this.submitted = true
      if (!this.deviceCode || !this.deviceName) {
        return
      }
      this.pairing = true
      // Post the device code to the server
      const path = '/api/v1/device-pairing/pair'
      await axios.post(path, { device_code: this.deviceCode, screen_name: this.deviceName })
        .then(() => {
          this.checkPairedSocket()
        })
        .catch((error) => {
          this.pairing = false
          // eslint-disable-next-line
            console.error(error);
          this.failed = false
        })
    },
    checkPairedSocket: async function () {
      const deviceCode = this.deviceCode
      const socket = new WebSocket(baseURLWS + '/ws/v1/device-pairing/check-pair-status/' + deviceCode)
      socket.onmessage = (ev) => {
        const res = JSON.parse(ev.data)
        switch (res.status) {
          case 'paired':
            this.pairing = false
            this.paired = true
            this.settingUp = true
            this.setupComplete = false
            this.failed = false
            break
          case 'complete':
            this.pairing = false
            this.paired = true
            this.settingUp = false
            this.setupComplete = true
            this.failed = false
            break
          case 'timeout':
            this.pairing = false
            this.paired = false
            this.settingUp = false
            this.setupComplete = false
            this.failed = true
            this.failureMessage = 'Pairing timed out. Check your device code or restart your device and try again.'
            break
          default:
            this.pairing = false
            this.paired = false
            this.settingUp = false
            this.setupComplete = false
            this.failed = true
            this.failureMessage = 'Server error: Incorrect websocket response.'
        }
      }
      socket.onerror = () => {
        // eslint-disable-next-line
        console.error(error);
        this.pairing = false
        this.settingUp = false
        this.setupComplete = false
        this.failed = false
      }
    },
    getSchedule () {
      store.dispatch('scheduling/getSchedule', this.userId)
    }

  }
}
</script>

<style scoped>

</style>
