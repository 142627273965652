<template>
  <div class="container" style="max-width: 576px">
    <h1 class="mt-2">Password recovery</h1>
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="email">Email Address</label>
        <input id="email" type="text" v-model="email" name="email" class="form-control"
               :class="{ 'is-invalid': submitted && !email }"/>
        <div v-if="submitted && !email" class="invalid-feedback">Please enter your email address</div>
      </div>

      <div class="form-group">
        <button v-if="!success" class="btn btn-primary mt-2" :disabled="requestPending">
          Continue
          <span v-if="requestPending" class="spinner-border spinner-border-sm" role="status"/>
        </button>
      </div>
      <div v-if="success" class="mx-auto alert alert-success my-2" role="alert">
        If an account is tied to this address, a reset email has been sent.
      </div>
      <div v-if="showError" class="mx-auto alert alert-danger my-2" role="alert">
        {{ errorMessage }}
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      email: '',
      submitted: false,
      requestPending: false,
      success: false,
      showError: false,
      errorMessage: 'Failed'
    }
  },
  name: 'UserPasswordForgot',
  methods: {
    handleSubmit () {
      this.submitted = true
      if (this.email) {
        this.requestPending = true
        this.showError = false
        const path = '/api/v1/auth/password-recovery/' + this.email
        axios.post(path)
          .then((response) => {
            this.requestPending = false
            if (response.status === 200) {
              this.success = true
            } else {
              this.success = false
              this.showError = true
              this.errorMessage = 'Request failed'
            }
          })
          .catch(() => {
            this.success = false
            this.showError = true
            this.errorMessage = 'Request failed'
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
