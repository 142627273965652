<template>
<!--  TODO Ask user for profile image.-->
  <div class="container-sm" style="max-width: 720px">
    <h1>Create New Account</h1>
    <form @submit.prevent="createUser">
      <div class="mx-auto">
        <label for="emailInput" class="form-label">Email</label>
        <input v-model="email" type="text" class="form-control" id="emailInput"
               :class="{ 'is-invalid': submitted && !email }">
        <div v-if="submitted && !email" class="invalid-feedback">This is required</div>
      </div>
      <div class="mx-auto">
        <label for="passwordInput">Password</label>
        <input type="password" id="passwordInput" v-model="password" name="password" class="form-control"
               :class="{ 'is-invalid': submitted && !password }"/>
        <div v-if="submitted && !password" class="invalid-feedback">This is required</div>
      </div>
      <div class="mx-auto">
        <label for="passwordConfirmInput">Confirm password</label>
        <div class="input-group">
          <input type="password" id="passwordConfirmInput" v-model="confirmPassword" name="password"
                 class="form-control"
                 :class="{ 'is-invalid': submitted && !confirmPassword }"/>
          <span v-if="password && (password === confirmPassword)" class="input-group-text"
                style="width: 50px; color: green; font-weight: bold;">✓</span>
          <span v-if="password && (password !== confirmPassword)" class="input-group-text"
                style="width: 50px; color: red; font-weight: bold;">🞩</span>
        </div>
      </div>

      <div class="mx-auto my-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="agreeTerms" required>
          <label class="form-check-label" for="agreeTerms">
            Agree to terms and conditions
          </label>
        </div>
      </div>
      <div class="mx-auto my-2">
        <button class="btn btn-primary" :disabled="creatingUser">
          Create User
          <span v-if="creatingUser" class="spinner-border spinner-border-sm" role="status"/>
        </button>
      </div>
      <div v-if="showError" class="mx-auto alert alert-danger my-2" role="alert">
        {{ errorMessage }}
      </div>

    </form>

  </div>
</template>

<script>
import axios from 'axios'
import router from '@/router'

export default {
  name: 'UserRegister',
  data: () => ({
    email: '',
    password: '',
    confirmPassword: '',
    submitted: false,
    creatingUser: false,
    showError: false,
    errorMessage: 'Error'
  }),
  methods: {
    createUser () {
      this.submitted = true
      this.showError = false
      this.errorMessage = 'Error'
      if (this.email && this.password && (this.password === this.confirmPassword)) {
        this.showError = false
        this.creatingUser = true
        const createUserPath = '/api/v1/user/'
        axios.post(createUserPath, { email: this.email, password: this.password })
          .then((response) => {
            // eslint-disable-next-line
              console.log(response)
            // If the server responds with a token, our login is valid
            if (response.data.refresh_token) {
              this.$store.commit('authentication/loginSuccess', response)
              this.creatingUser = false
              let nextPage = '/dashboard'
              if (this.$route.query.nextPage) {
                nextPage = this.$route.query.nextPage
              }
              router.push(nextPage).catch((error) => {
                // eslint-disable-next-line
                  console.error(error);
              })
            } else {
              // Likely a server error
              this.creatingUser = false
              this.showError = true
              this.errorMessage = 'Failed to create user'
            }
          })
          .catch((error) => {
            this.creatingUser = false
            this.showError = true
            if (error.response.data.detail[0]) {
              switch (error.response.data.detail[0].type) {
                case 'value_error.user_exists':
                  this.errorMessage = 'A user with that email address already exists'
                  break
                case 'value_error.email':
                  this.errorMessage = 'Invalid email address'
                  break
                default:
                  this.errorMessage = 'Failed to create user'
              }
            } else {
              this.errorMessage = 'Failed to create user'
            }
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
