<template>
  <image-dialogue v-on:selectImage="selectForegroundImage($event)"></image-dialogue>
  <template-dialogue v-on:selectTemplate="selectTemplateUuid($event)"></template-dialogue>
  <div class="border rounded mb-3">

    <form>
      <div class="row justify-content-end p-2">
        <button type="button"
                class="btn-close justify-content-end"
                @click="close()"
                aria-label="Close"></button>
      </div>

      <div class="row">
        <div class="col">
          <label for="display-message">Message</label>
          <input v-model="formDisplayText" @change="putEvent" id="display-message" type="text" class="form-control" placeholder="Text">
        </div>
      </div>
      <div class="row my-3">
        <div class="col col-auto">
          <label for="start-time">Start Time</label>
          <input
            id="start-time"
            v-model="formStartTime"
            type="time"
            class="form-control"
            placeholder="Select start time"
            @change="putEvent">
        </div>

        <div class="col col-auto">
          <label for="end-time">End Time</label>
          <input
            id="end-time"
            v-model="formEndTime"
            type="time"
            class="form-control"
            placeholder="Select start time"
            @change="putEvent">
        </div>
        <div class="col col-auto">
          <label>Date</label>
          <div class="input-group">
            <input
              v-model="formDate"
              type="date"
              class="form-control"
              placeholder="Select date"
              name="date">
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <div v-if="multipleScreens">
            <label for="screen-select">Screen</label>
            <select id="screen-select"
                    class="form-control form-select mb-4"
                    name="screen-select"
                    v-model="formScreenSelect">
              <option v-for="screen in screens" v-bind:key="screen" v-bind:value="screen.uuid">
                {{ screen.name }}
              </option>
            </select>
          </div>
        </div>

      </div>

      <h3>Image</h3>
      <div class="row border rounded py-1 my-3 bg-light">
        <div class="col-auto px-1">
          <img class="rounded" width="135"
               v-bind:src="userForegroundImages[formForegroundImageUuid] || ''"
               data-bs-toggle="modal"
               data-bs-target="#imageModal"
               data-bs-dismiss="modal"
               :style="{ cursor: 'pointer'}" alt="Selected Foreground Image">
        </div>
        <div class="col d-flex align-items-center flex-wrap">
          <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#imageModal">
            Update
          </button>
        </div>

      </div>
    </form>

    <div class="row">
      <div v-if="$props.new" class="btn btn-secondary" @click="postEvent()">Create</div>
      <div v-if="!$props.new" class="btn btn-danger" @click="deleteEvent($props.eventUuid)">
        Delete

      </div>

      <!--suppress HtmlUnknownTarget -->
      <div v-if="saving" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="saveFail" class="alert alert-danger" role="alert">Action failed. Please retry</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import format from 'date-fns/format'
import { parse } from 'date-fns'
import TemplateDialogue from '@/components/TemplateDialogue'
import ImageDialogue from '@/components/ImageDialogue'

export default {
  name: 'EventForm',
  props: {
    eventUuid: String,
    new: Boolean
  },
  emits: ['close', 'modified'],
  data () {
    return {
      formScreenSelect: null,
      formForegroundImageUuid: null,
      formDisplayText: null,
      formStartTime: null,
      formEndTime: null,
      formDate: null,
      saving: false,
      saveFail: false
    }
  },
  components: {
    TemplateDialogue,
    ImageDialogue
  },
  computed: mapState({
    userId: state => state.authentication.userId,
    userForegroundImages: state => state.images.userForegroundImages,
    events: state => state.events.scheduleEvents,
    screens: state => state.screens.screens,
    profileImage: state => state.images.profileImage,
    multipleScreens: state => state.screens.multipleScreens
  }),

  methods: {
    close () {
      this.$emit('close')
    },
    populateForm () {
      if (this.$props.new) {
        this.formForegroundImageUuid = this.profileImage.uuid
      } else {
        this.formDisplayText = this.events[this.$props.eventUuid].displayText
        this.formForegroundImageUuid = this.events[this.$props.eventUuid].foregroundImageUuid
        this.formDate = format(this.events[this.$props.eventUuid].eventStart, 'yyyy-MM-dd')
        this.formStartTime = format(this.events[this.$props.eventUuid].eventStart, 'HH:mm')
        this.formEndTime = format(this.events[this.$props.eventUuid].eventEnd, 'HH:mm')
        this.formScreenSelect = this.events[this.$props.eventUuid].screenUuid
      }
    },
    parseForm () {
      const date = new Date(this.formDate)
      // If the user only has one screen, set the selected screen to it
      if (!this.multipleScreens) {
        this.formScreenSelect = this.screens[0].uuid
      }
      return {
        uuid: this.$props.eventUuid,
        foregroundImageUuid: this.formForegroundImageUuid,
        displayText: this.formDisplayText,
        eventStart: parse(this.formStartTime, 'HH:mm', date).toISOString(),
        eventEnd: parse(this.formEndTime, 'HH:mm', date).toISOString(),
        screenUuid: this.formScreenSelect
      }
    },
    selectForegroundImage (imageUuid) {
      this.formForegroundImageUuid = imageUuid
      this.putEvent()
    },
    selectTemplateUuid (templateUuid) {
      this.formTemplateUuid = templateUuid
      this.putEvent()
    },
    putEvent () {
      if (!this.$props.new) {
        this.saving = true
        this.saveFail = false
        const data = this.parseForm()
        this.$store.dispatch('events/putEvent', data).then(() => {
          this.saving = false
          this.$emit('modified')
        }).catch(() => {
          this.saving = false
          this.saveFail = true
        })
      }
    },
    postEvent () {
      this.saving = true
      this.saveFail = false
      const data = this.parseForm()
      this.$store.dispatch('events/postEvent', data).then(() => {
        this.saving = false
        this.$emit('modified')
        this.$emit('close')
      }).catch(() => {
        this.saving = false
        this.saveFail = true
      })
    },
    deleteEvent (eventUuid) {
      this.saving = true
      this.saveFail = false
      this.$store.dispatch('events/deleteEvent', eventUuid).then(() => {
        this.$emit('modified')
        this.$emit('close')
      }).catch(() => {
        this.saving = false
        this.saveFail = true
      })
    }
  },
  created () {
    this.populateForm()
    if (this.screens === undefined) {
      this.$store.dispatch('screens/getScreens')
    }
  }

}
</script>

<style scoped>

</style>
