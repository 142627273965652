import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'
import Splash from '@/views/public/Splash'
import Login from '@/views/account-management/UserLogin'
import Dashboard from '@/views/noticehome/UserDashboard'
import Footer from '@/components/TheFooter'
import NavBar from '@/components/TheNavBar'
import UserSettings from '@/views/account-management/UserSettings'
import UserImages from '@/views/account-management/UserImages'
import UserNoticeHomes from '@/views/account-management/UserNoticeHomes'
import Schedule from '@/views/noticehome/ScreenSchedule'
import Events from '@/views/noticehome/UserEvents'
import Pair from '@/views/noticehome/DevicePair'
import About from '@/views/public/About'

import test from '@/views/test'
import UserRegister from '@/views/account-management/UserRegister'
import RegistrationComplete from '@/views/noticehome/RegistrationComplete'
import UserPasswordForgot from '@/views/account-management/UserPasswordForgot'
import UserPasswordReset from '@/views/account-management/UserPasswordReset'
import Shop from '@/views/public/Shop'
import UserPasswordChange from '@/views/account-management/UserPasswordChange'
import UserEmailChange from '@/views/account-management/UserEmailChange'
import UserAccountImage from '@/views/account-management/UserAccountImage'
const routes = [
  {
    path: '/'
  },
  {
    path: '/splash',
    name: 'Splash',
    components: {
      default: Splash
    }
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      header: NavBar,
      default: Login,
      footer: Footer
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      header: NavBar,
      default: Dashboard,
      footer: Footer
    }
  },
  {
    path: '/shop',
    name: 'Shop',
    components: {
      header: NavBar,
      default: Shop,
      footer: Footer
    }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    components: {
      header: NavBar,
      default: Schedule,
      footer: Footer
    }
  },
  {
    path: '/events',
    name: 'Events',
    components: {
      header: NavBar,
      default: Events,
      footer: Footer
    }
  },
  {
    path: '/register',
    name: 'UserRegister',
    components: {
      header: NavBar,
      default: UserRegister,
      footer: Footer
    }
  },
  {
    path: '/forgot-password',
    name: 'UserPasswordForgot',
    components: {
      header: NavBar,
      default: UserPasswordForgot,
      footer: Footer
    }
  },
  {
    path: '/reset-password',
    name: 'UserPasswordReset',
    components: {
      header: NavBar,
      default: UserPasswordReset,
      footer: Footer
    }
  },
  {
    path: '/registration-complete',
    name: 'RegistrationComplete',
    components: {
      header: NavBar,
      default: RegistrationComplete,
      footer: Footer
    }
  },

  {
    path: '/account',
    name: 'UserSettings',
    components: {
      header: NavBar,
      default: UserSettings,
      footer: Footer
    }
  },
  {
    path: '/change-password',
    name: 'UserPasswordChange',
    components: {
      header: NavBar,
      default: UserPasswordChange,
      footer: Footer
    }
  },
  {
    path: '/change-email',
    name: 'UserEmailChange',
    components: {
      header: NavBar,
      default: UserEmailChange,
      footer: Footer
    }
  },
  {
    path: '/set-account-image',
    name: 'UserAccountImage',
    components: {
      header: NavBar,
      default: UserAccountImage,
      footer: Footer
    }
  },
  {
    path: '/user-images',
    name: 'UserImages',
    components: {
      header: NavBar,
      default: UserImages,
      footer: Footer
    }
  },
  {
    path: '/about',
    name: 'About',
    components: {
      header: NavBar,
      default: About,
      footer: Footer
    }
  },
  {
    path: '/user-noticehomes',
    name: 'UserNoticeHomes',
    components: {
      header: NavBar,
      default: UserNoticeHomes,
      footer: Footer
    }
  },
  {
    path: '/pair',
    name: 'Pair',
    components: {
      header: NavBar,
      default: Pair,
      footer: Footer
    }
  },
  {
    path: '/test',
    name: 'Test',
    components: {
      default: test
    }
    // A page for me to test out stuff while learning
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/forgot-password', '/reset-password', '/about', '/splash', '/shop'] // List of pages that do not require authentication

  const { dispatch } = store
  let loggedIn = false
  if (store.state.authentication.accessToken) {
    loggedIn = true
  }
  // Redirect away from '/'
  if (to.path === '/') {
    if (loggedIn) {
      next({ name: 'Dashboard' })
      return
    } else {
      next({ name: 'Splash' })
      return
    }
  }

  // Verify authentication if required
  const authRequired = !publicPages.includes(to.path)
  if (authRequired) {
    // Redirect user to login page if we don't have an access token
    if (!loggedIn) {
      next({ name: 'Login', query: { nextPage: to.path } })
      return
    }
    // Refresh if the access token has expired
    const accessTokenExpiry = localStorage.getItem('accessTokenExp')
    if (Date.now() >= accessTokenExpiry * 1000) {
      dispatch('authentication/refresh', true).then(next)
    } else {
      next()
    }
    // eslint-disable-next-line brace-style
  }

  // redirect away from login page if requested when we have an access token
  else if (to.name === 'Login' && loggedIn) {
    next({ name: 'Dashboard' })
  } else {
    next()
  }
})

export default router
