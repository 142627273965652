import axios from 'axios'

// Todo load user images when we initialise the store, instead of every time we load a page.
// TODO These aws image URLS will time out eventually, need some way to force a reload if the page is open too long

const state = {
  // Images is a dict, the keys are image UUIDs and the value is a dict containing the image src
  userForegroundImages: {},
  publicForegroundImages: {},
  templatePreviews: {}
}

const actions = {
  async getImage ({ commit }, uuid) {
    const path = `/api/v1/image/${uuid}`
    axios.get(path).then((res) => {
      const data = {
        uuid: res.data.uuid,
        src: res.data.src
      }
      commit('addUserImageToStore', data)
    })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error)
      })
  },
  async getProfileImage ({ commit }, force) {
    if (!force && state.profileImage === undefined) { // FIXME This doesnt work; it loads every time
      const path = '/api/v1/image/profile/'
      axios.get(path).then((res) => {
        const data = {
          uuid: res.data.uuid,
          src: res.data.src
        }
        commit('addProfileImageToStore', data)
      })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
        })
    }
  },
  async getAllUserImages ({ commit }, thumbnail) {
    const path = '/api/v1/image'
    const params = {
      params: {
        thumbnail: thumbnail,
        public: false,
        user: true,
        foreground: true,
        background: false
      }
    }
    axios.get(path, params)
      .then((res) => {
        const images = res.data
        if (images.length > 0) {
          for (const image of images) {
            commit('addUserImageToStore', image)
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error)
      })
  },
  async getAllPublicImages ({ commit }, thumbnail) {
    const path = '/api/v1/image'
    const params = {
      params: {
        thumbnail: thumbnail,
        public: true,
        user: false,
        foreground: true,
        background: false
      }
    }
    axios.get(path, params)
      .then((res) => {
        const images = res.data
        if (images.length > 0) {
          for (const image of images) {
            commit('addPublicImageToStore', image)
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error)
      })
  },
  async getAllTemplatePreviews ({ commit }, thumbnail) {
    // todo
    const path = '/api/v1/image'
    const params = {
      params: {
        thumbnail: thumbnail,
        public: false,
        user: false,
        foreground: false,
        background: false,
        template_preview: true
      }
    }
    axios.get(path, params)
      .then((res) => {
        const images = res.data
        if (images.length > 0) {
          for (const image of images) {
            commit('addTemplatePreviewToStore', image)
          }
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error)
      })
  },
  async uploadUserImage ({ commit }, formData) {
    return new Promise((resolve, reject) => {
      const path = '/api/v1/image/upload'
      axios.post(path, formData).then((res) => {
        const data = {
          uuid: res.data.uuid,
          src: res.data.src
        }
        commit('addUserImageToStore', data)
        resolve(res)
      })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
          reject(error)
        })
    })
  },
  async changeUserProfileImage ({ commit }, formData) {
    return new Promise((resolve, reject) => {
      const path = '/api/v1/image/profile'
      axios.post(path, formData).then((res) => {
        const data = {
          uuid: res.data.uuid,
          src: res.data.src
        }
        commit('addUserImageToStore', data)
        resolve(res)
      })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
          reject(error)
        })
    })
  },
  async deleteImage ({ commit }, imageUuid) {
    return new Promise((resolve, reject) => {
      const path = `/api/v1/image/${imageUuid}`
      axios.delete(path)
        .then(() => {
          commit('removeImageFromStore', imageUuid)
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error)
          reject(new Error('Failed to delete image'))
        })
    })
  }
}

const mutations = {
  addPublicImageToStore (state, data) {
    state.publicForegroundImages[data.uuid] = data.src
  },
  addUserImageToStore (state, data) {
    state.userForegroundImages[data.uuid] = data.src
  },
  addTemplatePreviewToStore (state, data) {
    state.templatePreviews[data.template_uuid] = data.src
  },
  removeImageFromStore (state, imageUuid) {
    delete state.userForegroundImages[imageUuid]
  },
  addProfileImageToStore (state, data) {
    state.profileImage = data.src
  },
  clearStore (state) {
    Object.assign(state, getEmptyState())
  }
}

export const images = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}

const getEmptyState = () => {
  return {
    userForegroundImages: {},
    publicForegroundImages: {},
    profileImage: null,
    templatePreviews: {}
  }
}
