<template>
  <div>
    <h1>Delete Page</h1>
    <button class="delete-btn" @click="doDelete">Delete Page</button>
    <image-dialogue ref="ImageDialogue"></image-dialogue>
  </div>

  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#imageModal">
    Launch demo modal
  </button>

  <button @click="getSchedule">GetSchedule</button>
  <button @click="testCall">Test call</button>
   <div v-if="imog['asd']">tesasdfasd</div>
  <img class="rounded" width="135" height="135"
       v-bind:src="imog['asd']">

</template>

<script>
import ImageDialogue from '../components/ImageDialogue.vue'
import store from '@/store'
import axios from 'axios'

export default {
  data () {
    return {
      imog: this.$store.state.images.userForegroundImages
    }
  },
  components: { ImageDialogue },
  methods: {
    async testCall () {
      const path = '/api/schedule/78d51be38c744767aca936ffc7e6d55f'
      await axios.put(path, { hello: 'world' })
        .then((res) => {
          // eslint-disable-next-line
          console.log(res)
        })
        .catch((error) => {
          // eslint-disable-next-line
            console.error(error);
        })
    },
    getSchedule () {
      store.dispatch('scheduling/getSchedule', this.userId)
    }
  }
}
</script>

<style scoped>
.delete-btn {
  padding: 0.5em 1em;
  background-color: #eccfc9;
  color: #c5391a;
  border: 2px solid #ea3f1b;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
