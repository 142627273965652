<template>
<!--  TODO When you press enter on the display text screen, it does weird things-->
  <div v-if="userHasScreens" class="container">

    <time-picker-dialogue v-bind:title="'Create New Slot'" ref="TimePickerDialogue"
                          @return-time="postScheduleSlot"></time-picker-dialogue>
    <h1 class="mt-4 mb-4">Schedule</h1>

    <select v-if="multipleScreens"
            id="screen-select"
            class="form-control form-select mb-4"
            name="screen-select"
            v-model="activeScreen"
            @change="sortScheduleSlots()">
      <option v-for="screen in screens" v-bind:key="screen" v-bind:value="screen.uuid">
        {{ screen.name }}
      </option>
    </select>

      <!--    List of days -->
      <div class="btn-group btn-group-sm d-flex border" role="group" aria-label="Day selector buttons">
        <div v-for="weekday in weekdays" :key="weekday"
             class="btn btn"
             v-bind:class="{ 'btn-secondary': isActiveDay(weekday) }"
             @click="setActiveWeekday(weekday);">
          <div class="d-block d-lg-none">{{ weekday.substring(0, 3) }}</div>
          <div class="d-none d-lg-flex">{{ weekday }}</div>
        </div>
      </div>

      <!--    List of slots -->
      <div class="btn border d-grid mx-0 mb-1 mt-3 px-2"
           data-bs-toggle="modal"
           data-bs-target="#timePickerModal"
           data-bs-dismiss="modal">
          Create new
      </div>
      <div v-for="uuid in sortedDayArrays[activeWeekday]" :key="uuid"
           class="border d-grid mx-0 my-1 list-slot"
           v-bind:class="{ 'list-slot-expanded': isActiveSlot(uuid)}">
        <div class="btn list-slot-btn"
             v-bind:class="{'btn-secondary': isActiveSlot(uuid)}"
             @click="toggleSlot(uuid)">
          <div class="align-items-start">
            <div class="align-items-start">
              <strong>{{ activeSchedule[uuid].startTime.substring(0, 5) }} -
                {{ activeSchedule[uuid].endTime.substring(0, 5) }}</strong>
            </div>
            <i>{{ activeSchedule[uuid].displayText }}</i>
          </div>
        </div>
        <schedule-slot-form v-if="uuid === activeSlotUuid"
                            :slot-uuid="uuid"
                            :screen-uuid="activeScreen"
                            :start-time="activeSchedule[uuid].startTime"
                            :display-text="activeSchedule[uuid].displayText"
                            :template-uuid="activeSchedule[uuid].templateUuid"
                            :foreground-image-uuid="activeSchedule[uuid].foregroundImageUuid"
                            v-on:time-modified="sortScheduleSlots()"></schedule-slot-form>
      </div>
  </div>
  <div v-if="!userHasScreens && initialFetchComplete" class="container mt-4">
    <p class="">You currently have no NoticeHomes paired to your account.
      <router-link to="/shop">Visit our shop</router-link>
      to purchase a device or
      <router-link to="/pair"> pair an existing one.</router-link>
    </p>
  </div>
  <div v-if="!initialFetchComplete" class="container mt-4">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import ScheduleSlotForm from '@/components/ScheduleSlotForm'
import TimePickerDialogue from '@/components/TimePickerDialogue'

import { getSortedDailyUuidDict } from '@/helpers/parse-schedule'

export default {
  data () {
    return {
      showButton: true,

      initialFetchComplete: false,
      userHasScreens: true,
      activeScreen: null,
      weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      activeWeekday: null,
      activeSlotUuid: null,
      sortedDayArrays: { Monday: null } // Initialise with this value to stop error message
    }
  },
  name: 'Schedule.vue',
  components: { ScheduleSlotForm, TimePickerDialogue },
  computed: {
    activeSchedule () {
      return this.schedule[this.activeScreen]
    },
    ...mapState({
      userId: state => state.authentication.userId,
      schedule: state => state.scheduling.schedule,
      screens: state => state.screens.screens,
      multipleScreens: state => state.screens.multipleScreens
    })
  },
  methods: {
    postScheduleSlot (startTime) {
      this.saving = true
      this.saveSuccess = false
      this.saveFail = false
      const data = {
        weekday: this.activeWeekday,
        screenUuid: this.activeScreen,
        startTime: startTime
      }
      this.$store.dispatch('scheduling/postScheduleSlot', data)
        .then((res) => {
          this.sortScheduleSlots()
          this.activeSlotUuid = res.uuid
          this.saving = false
          this.saveSuccess = true
        }).catch(() => {
          this.saving = false
          this.saveFail = true
        })
    },
    setActiveWeekday (day) {
      this.activeWeekday = day
    },
    toggleSlot (slotUuid) {
      if (this.isActiveSlot(slotUuid)) {
        this.activeSlotUuid = 0
      } else {
        this.activeSlotUuid = slotUuid
      }
    },
    isActiveDay (day) {
      return day === this.activeWeekday
    },
    isActiveSlot (slotUUid) {
      return slotUUid === this.activeSlotUuid
    },
    sortScheduleSlots () {
      this.sortedDayArrays = getSortedDailyUuidDict(this.activeSchedule)
    },
    async refreshStore () {
      const promise1 = this.$store.dispatch('screens/getScreens')
      const promise2 = this.$store.dispatch('scheduling/getSchedule')
      const promise3 = this.$store.dispatch('images/getAllPublicImages')
      const promise4 = this.$store.dispatch('images/getAllUserImages')
      const promise5 = this.$store.dispatch('images/getAllTemplatePreviews')
      await Promise.all([promise1, promise2, promise3, promise4, promise5])
      if (this.screens.length === 0) {
        // eslint-disable-next-line
        console.log('User has no screens')
        this.userHasScreens = false
        this.initialFetchComplete = true
      } else {
        this.userHasScreens = true
        this.initialFetchComplete = true
        this.activeScreen = this.screens[0].uuid
        this.sortScheduleSlots()
      }
    }
  },
  created () {
    if (this.schedule === undefined || this.store === undefined) {
      this.refreshStore()
    } else {
      this.sortScheduleSlots()
    }
    this.activeWeekday = 'Monday'
  }
}

</script>

<style scoped>
.list-slot {
  transition: max-height 0.2s ease-out;
  transition-duration: 0.2s;
  height: 70px;
  transition-property: height;
  overflow: hidden;
}
.list-slot-expanded{
  transition: max-height 0.25s ease-out;
  transition-duration: 0.5s;
  height: 750px;
  transition-property: height;
}
.list-slot-btn{
  height: 70px;
}
</style>
