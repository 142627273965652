<template>
  <div class="container-sm">

    <div class="container bg-light p-4 mt-3">
      <h1>Change your email</h1>
      <form @submit.prevent="changeEmail">
        <div class="mx-auto">
          <label for="emailInput" class="form-label">Email</label>
          <input v-model="email" type="text" class="form-control" id="emailInput"
                 :class="{ 'is-invalid': submitted && !email }">
          <div v-if="submitted && !email" class="invalid-feedback">This is required</div>
        </div>

        <div class="mx-auto my-2">
          <button type="submit" class="btn btn-primary" :disabled="emailChangePending">
            Change Email
            <span v-if="emailChangePending" class="spinner-border spinner-border-sm" role="status"/>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserEmailChange',
  data: () => ({
    email: '',
    submitted: false,
    emailChangePending: false,
    showError: false,
    errorMessage: 'Error'
  }),
  methods: {
    changeEmail () {
      // TODO Get password to confirm
      this.submitted = true
      this.emailChangePending = true
      this.showError = false
      this.errorMessage = 'Error'
      this.showError = false
      const editEmailPath = '/api/v1/user/'
      axios.put(editEmailPath, {
        email: this.email
      })
        .then((response) => {
          this.emailChangePending = false
          // eslint-disable-next-line
          console.log(response)
          // If the server responds with a token, our login is valid
          if (response.data.user) {
            //  TODO update store
            // eslint-disable-next-line
            console.log('Success')
          } else {
            // Likely a server error
            this.showError = true
            this.errorMessage = 'Failed to change email'
          }
        })
        .catch((error) => {
          this.showError = true
          this.emailChangePending = false
          if (error.response.data.detail[0]) {
            switch (error.response.data.detail[0].type) {
              case 'value_error.user_exists':
                this.errorMessage = 'A user with that email address already exists'
                break
              case 'value_error.email':
                this.errorMessage = 'Invalid email address'
                break
              default:
                this.errorMessage = 'Failed to create user'
            }
          } else {
            this.errorMessage = 'Failed to create user'
          }
        })
    }
  },
  created () {
    this.email = this.$store.state.authentication.email
  }
}

</script>
