<template>
  <div class="container-sm">
    <div class="container bg-light p-4 mt-3">
      <h1>Your NoticeHomes</h1>
      <div class="alert alert-warning" v-if="deleteFailed">Failed to delete screen</div>
      <div class="alert alert-success" v-if="deleteSuccess">Screen has been removed from your account</div>
      <ul class="list-group">
        <li v-for="screen in screens" v-bind:key="screen" class="list-group-item mt-2">
          {{ screen.name }}
          <button class="btn btn-danger"
                  data-bs-toggle="modal"
                  v-bind:data-bs-target="'#nh-' + screen.uuid">
            Delete
          </button>
          <confirm-delete-modal v-bind:id="'nh-'+screen.uuid" v-bind:deleting-object-name="screen.name" v-on:confirm="deleteNoticeHome(screen.uuid)"></confirm-delete-modal>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal'
export default {
  name: 'UserNoticeHomes',
  data () {
    return {
      deleteSelection: null,
      deleteFailed: false,
      deleteSuccess: false
    }
  },
  computed: mapState({
    screens: state => state.screens.screens
  }),
  created () {
    this.$store.dispatch('screens/getScreens')
  },
  components: { ConfirmDeleteModal },
  methods: {
    deleteNoticeHome (screenUuid) {
      this.deleteFailed = false
      this.deleteSuccess = false
      this.$store.dispatch('screens/deleteScreen', screenUuid).then(() => {
        this.deleteSuccess = true
      }).catch(() => {
        this.deleteFailed = true
      })
    }
  }
}
</script>
