<template>
  <div class="container-sm">

    <div class="container bg-light p-4 mt-3">
      <h1>Change your password</h1>
      <form @submit.prevent="changePassword">
        <div class="mx-auto">
          <label for="passwordInput">Password</label>
          <input type="password" id="passwordInput" v-model="password" name="password" class="form-control"
                 :class="{ 'is-invalid': submitted && !password }"/>
          <div v-if="submitted && !password" class="invalid-feedback">This is required</div>
        </div>
        <div class="mx-auto">
          <label for="passwordConfirmInput">Confirm password</label>
          <div class="input-group">
            <input type="password" id="passwordConfirmInput" v-model="confirmPassword" name="password"
                   class="form-control"
                   :class="{ 'is-invalid': submitted && !confirmPassword }"/>
            <span v-if="password && (password === confirmPassword)" class="input-group-text"
                  style="width: 50px; color: green; font-weight: bold;">✓</span>
            <span v-if="password && (password !== confirmPassword)" class="input-group-text"
                  style="width: 50px; color: red; font-weight: bold;">🞩</span>
          </div>
        </div>

        <div class="mx-auto my-2">
          <button class="btn btn-primary" :disabled="passwordChangePending">
            Change Password
            <span v-if="passwordChangePending" class="spinner-border spinner-border-sm" role="status"/>
          </button>
        </div>
        <div v-if="showError" class="mx-auto alert alert-danger my-2" role="alert">
          {{ errorMessage }}
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserPasswordChange',
  data: () => ({
    password: '',
    confirmPassword: '',
    submitted: false,
    passwordChangePending: false,
    showError: false,
    errorMessage: 'Error'
  }),
  methods: {
    changePassword () {
      // TODO Get current password to confirm
      this.submitted = true
      this.showError = false
      this.errorMessage = 'Error'
      if (this.password && (this.password === this.confirmPassword)) {
        this.showError = false
        this.passwordChangePending = true
        const createUserPath = '/api/v1/user/'
        axios.put(createUserPath, {
          password: this.password
        })
          .then((response) => {
            this.passwordChangePending = false
            // eslint-disable-next-line
            console.log(response)
            if (response.data.user) {
            } else {
              // Likely a server error
              this.showError = true
              this.errorMessage = 'Failed to change password'
            }
          })
          .catch(() => {
            this.passwordChangePending = false
            this.showError = true
            this.errorMessage = 'Failed to change password'
          })
      }
    }
  }
}

</script>
