import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import '../scss/custom.scss'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css' // todo removed these 2 bootstrap lines when modifying branding but it broke bootstrap js and i forgot why so i re added them. need to sort it properly
import axios from 'axios'
import { baseURL } from '@/config'

// Get the baseURL for backend requests from the config file
axios.defaults.baseURL = baseURL

// Add an authorisation header to each axios request, using the access token in the localstorage
axios.defaults.headers.common.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('accessToken'))

createApp(App).use(store).use(router).mount('#app')
