<template>
  <div class="container-sm" style="max-width: 720px">
    <h1>Create a new password</h1>
    <form @submit.prevent="handleSubmit">

      <div class="mx-auto">
        <label for="passwordInput">Password</label>
        <input type="password" id="passwordInput" v-model="password" name="password" class="form-control"
               :class="{ 'is-invalid': submitted && !password }"/>
        <div v-if="submitted && !password" class="invalid-feedback">This is required</div>
      </div>
      <div class="mx-auto">
        <label for="passwordConfirmInput">Confirm password</label>
        <div class="input-group">
          <input type="password" id="passwordConfirmInput" v-model="confirmPassword" name="password"
                 class="form-control"
                 :class="{ 'is-invalid': submitted && !confirmPassword }"/>
          <span v-if="password && (password === confirmPassword)" class="input-group-text"
                style="width: 50px; color: green; font-weight: bold;">✓</span>
          <span v-if="password && (password !== confirmPassword)" class="input-group-text"
                style="width: 50px; color: red; font-weight: bold;">🞩</span>
        </div>
      </div>

      <div class="mx-auto my-2">
        <button class="btn btn-primary" :disabled="requestPending">
          Change password
          <span v-if="requestPending" class="spinner-border spinner-border-sm" role="status"/>
        </button>
      </div>
      <div v-if="showError" class="mx-auto alert alert-danger my-2" role="alert">
        {{ errorMessage }}
      </div>
      <div v-if="success" class="mx-auto alert alert-success my-2" role="alert">
        Password changed successfully. You can now <router-link to="/login">login</router-link> using your new password
      </div>

    </form>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserPasswordReset',
  data: () => ({
    password: '',
    confirmPassword: '',
    submitted: false,
    requestPending: false,
    success: false,
    showError: false,
    errorMessage: 'Failed to change password'
  }),
  methods: {
    handleSubmit () {
      this.submitted = true
      this.showError = false
      this.errorMessage = 'Error'
      if (this.password && (this.password === this.confirmPassword)) {
        this.requestPending = true
        const path = '/api/v1/auth/reset-password'
        axios.post(path, { token: this.$route.query.token, new_password: this.password })
          .then((response) => {
            this.requestPending = false
            if (response.status === 200) {
              this.success = true
            } else {
              this.success = false
              this.showError = true
              this.errorMessage = 'Failed to change password'
            }
          })
          .catch(() => {
            this.requestPending = false
            this.success = false
            this.showError = true
            this.errorMessage = 'Failed to change password'
          })
      }
    }
  }
}
</script>

<style scoped>

</style>
