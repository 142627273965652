<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <router-link to="/">
        <img class="navbar-brand" src="~@/assets/logo-full.png" alt="logo">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/shop">
              <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Shop</div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/about">
              <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">About</div>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/dashboard">
              <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">My NoticeHome</div>
            </router-link>
          </li>
        </ul>
        <!-- Shown when no login -->
        <div v-if="!email" class="d-flex" role="group">
          <router-link :to="{ name: 'Login', query: { nextPage: loginNextPage }}">
            <button class="btn btn-outline-light mx-1" type="submit" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Login</button>
          </router-link>
          <router-link :to="{ name: 'UserRegister', query: { nextPage: loginNextPage }}">
            <button class="btn btn-outline-light mx-1" type="submit" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Register</button>
          </router-link>
        </div>
        <!-- Shown when user logged in -->
        <ul v-else class="navbar-nav">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link class="nav-link" to="/account">
                <div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Account</div>
              </router-link>
            </li>

            <li><a class="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" @click="logOut" href="#">Logout</a></li>
          </ul>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
// import bootstrap from 'bootstrap/dist/js/bootstrap'

export default {
  name: 'KenbanNavBar',
  components: {},
  data () {
    return {
      toggled: false
    }
  },
  computed: {
    loginNextPage () {
      if (this.$route.query.nextPage) {
        return this.$route.query.nextPage
      }
      return this.$route.path
    },
    ...mapState({
      email: state => state.authentication.email
    })
  },

  methods: {
    onTitleClick (evt) {
      this.$emit('title-click', evt)
    },
    closeMenu () {
      this.toggled = false
    },
    logOut () {
      this.$store.dispatch('authentication/logout')
    }
  }
}

// const navLinks = document.querySelectorAll('.nav-item')
// const menuToggle = document.getElementById('navbarSupportedContent')
// const bsCollapse = new bootstrap.Collapse(menuToggle)
// navLinks.forEach((l) => {
//   l.addEventListener('click', () => {
//     bsCollapse.toggle()
//   })
// })
</script>

<style scoped>
.navbar-brand {
  height: 50px;
}
</style>
