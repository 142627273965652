import axios from 'axios'
import { addEndTimes, keysToCamel, keysToSnake } from '@/helpers'

const getDefaultState = () => {
  return {
    schedule: {}
  }
}

const state = getDefaultState()
// schedule is an dictionary of screen uuids, linked to dictionaries of slot uuids

const actions = {
  async getSchedule ({ commit }) {
    /**
         * Get the user's schedule from the backend, and save it to store
         */
    const path = '/api/v1/schedule/'
    await axios.get(path)
      .then((res) => {
        const scheduleSlots = keysToCamel(res.data)
        if (scheduleSlots.length > 0) {
          // Get a list of unique screen uuids
          const screenUuids = [...new Set(scheduleSlots.map(slot => slot.screenUuid))]
          // Commit a schedule for each screen
          for (const screenUuid of screenUuids) {
            // Create an array of slots matching the screen uuid
            const screenSlotArray = scheduleSlots.filter(slot => slot.screenUuid === screenUuid)
            // Turn the array into an object, using slot uuid as the keys
            const screenDict = Object.assign({}, ...screenSlotArray.map((x) => ({ [x.uuid]: x })))
            const payload = {
              screenSchedule: screenDict,
              screenUuid: screenUuid
            }
            commit('setScreenSchedule', payload)
          }
        }
        return state.schedule
      })
      .catch((error) => {
        // eslint-disable-next-line
                console.error(error);
      })
  },
  async putScheduleSlot ({ commit }, slot) {
    return new Promise((resolve, reject) => {
      let data = JSON.parse(JSON.stringify(slot)) // Deep copy slot, so we can remove unnecessary properties
      delete data.endTime
      data = keysToSnake(data)
      const path = `/api/v1/schedule/${data.uuid}`
      axios.put(path, data)
        .then((res) => {
          const scheduleSlot = res.data
          commit('setScheduleSlot', scheduleSlot)
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(new Error('Failed to edit schedule slot'))
        })
    })
  },
  async postScheduleSlot ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const path = '/api/v1/schedule/'
      // eslint-disable-next-line
            console.log(payload);
      axios.post(path, keysToSnake(payload))
        .then((res) => {
          const scheduleSlot = res.data
          commit('setScheduleSlot', scheduleSlot)
          resolve(scheduleSlot)
        })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(new Error('Failed to create schedule slot'))
        })
    })
  },
  async deleteScheduleSlot ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const path = `/api/v1/schedule/${payload.slotUuid}`
      axios.delete(path)
        .then(() => {
          commit('removeScheduleSlot', payload)
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line
                    console.error(error);
          reject(new Error('Failed to delete schedule slot'))
        })
    })
  }
}

const mutations = {
  setScheduleSlot (state, slot) {
    slot = keysToCamel(slot)
    state.schedule[slot.screenUuid][slot.uuid] = slot
    state.schedule[slot.screenUuid] = addEndTimes(state.schedule[slot.screenUuid])
  },
  setScreenSchedule (state, payload) {
    state.schedule[payload.screenUuid] = addEndTimes(payload.screenSchedule)
  },
  removeScheduleSlot (state, payload) {
    // todo this didnt work last time i checked
    delete state.schedule[payload.screenUuid][payload.slotUuid]
    state.schedule[payload.screenUuid] = addEndTimes(state.schedule[payload.screenUuid])
  },
  clearStore (state) {
    Object.assign(state, getDefaultState())
  }
}

export const scheduling = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}
