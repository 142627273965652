<template>
<!--  TODO Implement time format-->
  <image-dialogue v-on:selectImage="selectForegroundImage($event)"></image-dialogue>
  <template-dialogue v-on:selectTemplate="selectTemplateUuid($event)"></template-dialogue>
  <div class="col m-1 py-2 px-4">
    <form>
      <div class="mb-3">
          <label for="start-time">Start Time</label>
          <input
              id="start-time"
              v-model="formStartTime"
              type="time"
              class="form-control"
              placeholder="Select start time"
              @change="saveTime()">
        <label for="display-message">Message</label>
        <input id="display-message" type="text" class="form-control" placeholder="Text" v-model="formDisplayText"
               @change="saveMessage()">

      </div>

      <h3>Background Template</h3>
      <div class="row border rounded py-1 my-3 bg-light">
        <div class="col-auto px-1">
          <img v-bind:src="templatePreviews[formTemplateUuid]" alt="Image"
               data-bs-toggle="modal"
               data-bs-target="#templateModal"
               data-bs-dismiss="modal"
               class="border rounded"
               :style="{ cursor: 'pointer', width: '300px'}">
        </div>
        <div class="col d-flex align-items-center">
          <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#templateModal">
            Update
          </button>
        </div>
      </div>
      <h3>Foreground Image</h3>
      <div class="row border rounded py-1 my-3 bg-light">
        <div class="col-auto px-1">
          <img class="rounded" width="135"
               v-bind:src="allForegroundImages[formForegroundImageUuid]"
               data-bs-toggle="modal"
               data-bs-target="#imageModal"
               data-bs-dismiss="modal"
               :style="{ cursor: 'pointer'}" alt="Foreground Image">
        </div>
        <div class="col d-flex align-items-center flex-wrap">
          <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#imageModal">
            Update
          </button>
        </div>

      </div>
    </form>
    <div class="row">
      <div class="btn btn-danger" @click="deleteScheduleSlot(slotUuid)">Delete</div>
      <div v-if="saving" class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="saveFail" class="alert alert-danger" role="alert">Failed to save</div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TemplateDialogue from '@/components/TemplateDialogue'
import ImageDialogue from '@/components/ImageDialogue'

export default {
  data () {
    return {
      initialFetchComplete: false,
      showSlotInfo: false,
      activeWeekday: null,
      formStartTime: null,
      formDisplayText: null,
      formTemplateUuid: null,
      formForegroundImageUuid: null,
      saving: false,
      saveSuccess: false,
      saveFail: false
    }
  },
  name: 'ScheduleSlotForm',
  components: { TemplateDialogue, ImageDialogue },
  emits: ['timeModified'],
  props: {
    slotUuid: String,
    screenUuid: String,
    startTime: String,
    displayText: String,
    templateUuid: String,
    foregroundImageUuid: String
  },
  computed: {
    activeSchedule () {
      return this.schedule[this.slotUuid]
    },
    allForegroundImages () {
      return {
        ...this.userForegroundImages,
        ...this.publicForegroundImages
      }
    },
    ...mapState({
      userId: state => state.authentication.userId,
      userForegroundImages: state => state.images.userForegroundImages,
      publicForegroundImages: state => state.images.publicForegroundImages,
      templatePreviews: state => state.images.templatePreviews,
      schedule: state => state.scheduling.schedule,
      screens: state => state.screens.screens,
      multipleScreens: state => state.screens.multipleScreens
    })
  },
  methods: {
    populateForm () {
      this.formStartTime = this.startTime
      this.formDisplayText = this.displayText
      this.formTemplateUuid = this.templateUuid
      this.formForegroundImageUuid = this.foregroundImageUuid
    },
    saveTime () {
      this.putScheduleSlot({ startTime: this.formStartTime })
    },
    saveMessage () {
      this.putScheduleSlot({ displayText: this.formDisplayText })
    },
    selectForegroundImage (imageUuid) {
      this.formForegroundImageUuid = imageUuid
      this.putScheduleSlot({ foregroundImageUuid: imageUuid })
    },
    selectTemplateUuid (templateUuid) {
      this.formTemplateUuid = templateUuid
      this.putScheduleSlot({ templateUuid: templateUuid })
    },
    putScheduleSlot (data) {
      data.uuid = this.slotUuid
      this.$store.dispatch('scheduling/putScheduleSlot', data).then(() => {
        this.saving = false
        this.saveSuccess = true
        if ('startTime' in data) {
          this.$emit('timeModified')
        }
      }).catch(() => {
        this.saving = false
        this.saveFail = true
      })
    },
    deleteScheduleSlot (slotUuid) {
      // todo prevent deletion of the first (ie midnight start)
      this.saving = true
      this.saveSuccess = false
      this.saveFail = false
      const payload = { slotUuid: slotUuid, screenUuid: this.screenUuid }
      this.$store.dispatch('scheduling/deleteScheduleSlot', payload).then(() => {
        this.$emit('timeModified')
        this.saving = false
        this.saveSuccess = true
      }).catch(() => {
        this.saving = false
        this.saveFail = true
      })
    }

  },
  created () {
    this.populateForm()
  }
}
</script>

<style scoped>

</style>
