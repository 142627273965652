<template>
  <div class="container-sm">

    <div class="container bg-light p-4 mt-3">
      <h1>Set Account Image</h1>

        <div v-if="!uploadComplete" class="input-group mb-2" v-bind:class="{ 'is-invalid': uploadIsInvalid}">
          <input type="file"
                 accept="image/*"
                 class="form-control"
                 v-bind:class="{ 'is-invalid': uploadIsInvalid}"
                 @change=setImage aria-label="Upload image"
                 aria-describedby="imageUploadFeedback">
          <button class="btn btn-outline-secondary" @click="uploadProfileImage" type="button">Upload</button>
        </div>
        <div id="imageUploadFeedback" class="invalid-feedback">
          Please select a file to upload.
        </div>
        <div v-if="uploading" class="spinner-border" role="status">
          <span class="visually-hidden">Uploading...</span>
        </div>
        <img v-if="uploadPreview" v-bind:src="uploadPreview" class="rounded mx-auto d-block mb-2" width="200" alt="Your Image"/>
    </div>

  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'UserAccountImage',
  data: () => ({
    title: undefined,
    uploadImage: null,
    uploadInputValue: undefined, // manipulated to remove filename from image input field
    uploadPreview: null,
    uploadIsInvalid: false,
    uploadComplete: false,
    showPublicImages: true,
    showUserImages: false,
    showUploadImage: false,
    uploading: false,
    uploadFailed: false
  }),
  methods: {
    // TODO Haven't fully finished this
    uploadProfileImage () {
      // If there is no image preview, invalidate the upload
      this.uploadFailed = false
      if (this.uploadImage == null) {
        this.uploadIsInvalid = true
        return
      }
      this.uploadIsInvalid = false
      this.uploading = true
      this.uploadPreview = null
      // upload image as a form
      const formData = new FormData()
      formData.append('image', this.uploadImage, this.uploadImage.name)
      formData.append('profile_image', 'true')
      this.$store.dispatch('images/changeUserProfileImage', formData).then(res => {
        this.uploading = false
        this.uploadComplete = true
        this.$store.dispatch('images/getProfileImage')
        router.push('/account')
      }).catch(error => {
        // eslint-disable-next-line
        console.log(error)
        this.uploading = false
        this.uploadFailed = true
      })
    },
    setImage (e) {
      // When uploading, set the image as soon as the user selects it, so it can be displayed
      this.uploadImage = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(this.uploadImage)
      reader.onload = e => {
        this.uploadPreview = e.target.result
      }
      this.uploadIsInvalid = false
    }
  }
}

</script>
