
const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export function getSortedDailyUuidDict (schedule) {
  /**
     * Take a schedule return an object day: [UUIDs] containing the slots that occur in that
     * day, sorted by start time.
     */
  if (!schedule) {
    return {}
  }
  const sortedDict = {}
  for (const weekday of weekdays) {
    // Put the slots into an array if they occur on the day
    const slotArray = []
    for (const [, value] of Object.entries(schedule)) {
      if (value.weekday === weekday) {
        slotArray.push(value)
      }
    }
    // Sort the new array
    slotArray.sort(function (a, b) {
      return a.startTime.localeCompare(b.startTime)
    })
    // Translate into an array containing only the UUIDs
    const uuidArray = []
    slotArray.forEach(slot => {
      uuidArray.push(slot.uuid)
    })
    sortedDict[weekday] = uuidArray
  }
  return sortedDict
}

/**
 * Get the active slot for a schedule
 */
export function activeSlot (schedule) {
  if (!schedule) {
    return {}
  }
}

/**
 * Give each slot an end time, based on the start time of the previous slot
 * Loop through each day of the week
 * @returns {*}
 * @param scheduleDict a single screen's schedule
 */
export function addEndTimes (scheduleDict) {
  // Put the slots into an array so we can order them according to start time
  const scheduleArray = Object.values(scheduleDict)
  scheduleArray.sort(function (a, b) {
    return a.startTime.localeCompare(b.startTime)
  })
  // Iterate over each day and add the end time (start time of next slot)
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  for (const weekday of weekdays) {
    const daySchedule = scheduleArray.filter(word => word.weekday === weekday)
    let i = 0
    for (i = 0; i < daySchedule.length - 1; i++) {
      // Make the edit directly in the original dict
      if (scheduleDict[daySchedule[i].uuid] != null) { scheduleDict[daySchedule[i].uuid].endTime = daySchedule[i + 1].startTime }
    }
    if (daySchedule[i] != null) {
      daySchedule[i].endTime = '23:59' // The last time in the list always has midnight as end time
    }
  }
  return scheduleDict
}
