<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="row">
        <div class="col text-center">
          <router-link to="/set-account-image">
            <img id="accountImage" class="img-thumbnail rounded-circle" :src="profileImage" alt="Account image">
          </router-link>
        </div>
      </div>
      {{ email }}
      <div class="row">
        <router-link to="/set-account-image">
          <div class="d-grid my-4">
            <button class="btn btn-lg btn-primary">Set account image</button>
          </div>
        </router-link>
      </div>
      <div class="row">
        <router-link to="/change-email">
          <div class="d-grid my-4">
            <button class="btn btn-lg btn-primary">Change email</button>
          </div>
        </router-link>
      </div>
      <div class="row">
        <router-link to="/change-password">
          <div class="d-grid my-4">
            <button class="btn btn-lg btn-primary">Change password</button>
          </div>
        </router-link>
      </div>
      <div class="row">
        <router-link to="/user-images">
          <div class="d-grid my-4">
            <button class="btn btn-lg btn-primary">View your images</button>
          </div>
        </router-link>
      </div>
      <div class="row">
        <router-link to="/user-noticehomes">
          <div class="d-grid my-4">
            <button class="btn btn-lg btn-primary">View your NoticeHomes</button>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'UserRegister',
  computed: {
    email () {
      return this.$store.state.authentication.email
    },
    profileImage () {
      return this.$store.state.images.profileImage
    }
  },
  created () {
    this.$store.dispatch('images/getProfileImage')
  }
}

</script>

<style scoped>
#accountImage {
  width: 100px;
  height: 100px;
}
</style>
