<template>
  <div v-if="userHasScreens">
    <create-quick-event-modal :screen-uuid="activeScreen.uuid"></create-quick-event-modal>
    <div class="container">
      <div class="col">
        <div class="row mx-2 my-1">
          <select v-if="multipleScreens"
                  id="screen-select"
                  class="form-control form-select mb-4"
                  name="screen-select"
                  v-model="activeScreen"
                  @change="setActiveScreen()">
            <option v-for="screen in screens" v-bind:key="screen" v-bind:value="screen">
              {{ screen.name }}
            </option>
          </select>
        </div>
        <div id="preview-row" class="thumbnail-container">
            <div id="preview" class="thumbnail">
              <iframe :src=activeScreenPreviewSrc></iframe>
            </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="row">
            <div class="d-grid my-4">
              <button type="button" class="btn btn-lg btn-primary" data-bs-toggle="modal" data-bs-target="#createQuickEventModal">
                Add quick reminder
              </button>
            </div>
          </div>
          <div class="row">
            <router-link to="/events">
              <div class="d-grid my-4">
                <button class="btn btn-lg btn-primary">View reminders</button>
              </div>
            </router-link>
          </div>
          <div class="row">
            <router-link to="/schedule">
              <div class="d-grid my-4">
                <button class="btn btn-lg btn-primary">Edit daily schedule</button>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="initialFetchComplete" class="container mt-4">
    <p class="">You currently have no NoticeHomes paired to your account.
      <router-link to="/shop">Visit our shop</router-link>
      to purchase a device or
      <router-link to="/pair"> pair an existing one.</router-link>
    </p>
  </div>
</template>

<script>

import axios from 'axios'
import CreateQuickEventModal from '@/components/CreateQuickEventModal'
import { mapState } from 'vuex'
import { keysToCamel } from '@/helpers'
import { baseURL } from '@/config'
import store from '@/store'

export default {

  data () {
    return {
      activeScreen: null,
      activeSlot: null,
      activeScreenPreviewSrc: null,
      userHasScreens: false,
      initialFetchComplete: false
    }
  },
  name: 'Dashboard',
  computed: mapState({
    userId: state => state.authentication.userId,
    events: state => state.events.scheduleEvents,
    screens: state => state.screens.screens,
    multipleScreens: state => state.screens.multipleScreens
  }),
  methods: {
    async setActiveScreen () {
      if (!this.userHasScreens) {
        return
      }
      if (this.activeScreen == null) {
        this.activeScreen = this.screens[0]
      }
      try {
        const response = await axios.get('/api/v1/schedule/screen-active-slot/' + this.activeScreen.uuid)
        this.activeSlot = keysToCamel(response.data)
        const url = new URL(baseURL + '/api/v1/template/preview/' + this.activeSlot.templateUuid)
        url.searchParams.append('display_text', this.activeSlot.displayText)
        url.searchParams.append('foreground_image_uuid', this.activeSlot.foregroundImageUuid)

        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + store.state.authentication.accessToken
          }
        }

        const fetchResponse = await fetch(url, requestOptions)
        const fetchedContent = await fetchResponse.text()

        // Set the content of the iframe element
        this.$nextTick(() => {
          const iframe = this.$el.querySelector('#preview iframe')
          iframe.contentDocument.open()
          iframe.contentDocument.write(fetchedContent)
          iframe.contentDocument.close()
        })
      } catch (error) {
        console.error('Error fetching the content:', error)
      }
    },
    async refreshStore () {
      this.$store.dispatch('screens/getScreens').then((res) => {
        if (this.screens.length === 0) {
          this.userHasScreens = false
          this.initialFetchComplete = true
        } else {
          this.userHasScreens = true
          this.initialFetchComplete = true
          this.setActiveScreen()
        }
      })
    }
  },
  components: { CreateQuickEventModal },
  created () {
    this.refreshStore()
  }
}

</script>

<style>

@media only screen and (max-width: 400px) {
  * {
    --var-preview-scale: 0.18;
  }
}

@media only screen and (min-width: 401px) and (max-width: 500px) {
  * {
    --var-preview-scale: 0.2;
  }
}

@media only screen and (min-width: 501px) and (max-width: 1000px) {
  * {
    --var-preview-scale: 0.26;
  }
}

@media only screen and (min-width: 1001px) {
  * {
    --var-preview-scale: 0.5;
  }
}

/*Careful modifying these thumbnail values, it sometimes throws off the width of modals*/

.thumbnail iframe {
  width: 1920px;
  height: 1080px;
  position: center;
}

.thumbnail {
  transform: scale(var(--var-preview-scale));
  transform-origin: 0 0;
}

.thumbnail-container {
  height: calc(1080px * var(--var-preview-scale));
}
</style>
