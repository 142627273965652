import { parseISO } from 'date-fns'
import format from 'date-fns/format'

export function parseIncomingDateTimes (event) {
  event.eventStart = parseISO(event.eventStart)
  event.eventEnd = parseISO(event.eventEnd)
  event.date = format(event.eventStart, 'yyyy-MM-dd')
  return event
}

export function getEventDaysArray (events) {
  /**
     * Returns an array of dates for which an event occurs
     */
  const dateArray = []
  for (const [, event] of Object.entries(events)) {
    if (!dateArray.includes(event.date)) {
      dateArray.push(event.date)
    }
  }
  dateArray.sort(function (a, b) {
    return new Date(a) - new Date(b)
  })
  return dateArray
}

export function getSortedDailyEventsDict (events) {
  const dailyDict = {}
  for (const [, event] of Object.entries(events)) {
    if (dailyDict[event.date]) {
      dailyDict[event.date].push(event.uuid)
    } else {
      dailyDict[event.date] = [event.uuid]
    }
  }
  // Sort each day's array by start time
  for (const [, eventArray] of Object.entries(dailyDict)) {
    eventArray.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date)
    })
  }
  return dailyDict
}
