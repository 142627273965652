import axios from 'axios'

const getDefaultState = () => {
  return {
    screens: [],
    multipleScreens: false
  }
}

const state = getDefaultState()

const actions = {
  async getScreens ({ commit }) {
    /**
         * Get the user's screens from the backend, and save it to store
         */
    const path = '/api/v1/screen/'
    await axios.get(path)
      .then((res) => {
        const screens = res.data
        if (screens.length > 0) {
          commit('setScreenList', screens)
        }
        return state.schedule
      })
      .catch((error) => {
        // eslint-disable-next-line
                console.error(error);
      })
  },
  async deleteScreen ({ commit }, screenUuid) {
    return new Promise((resolve, reject) => {
      const path = `/api/v1/screen/${screenUuid}`
      axios.delete(path)
        .then(() => {
          commit('removeScreenFromStore', screenUuid)
          resolve()
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
          reject(new Error('Failed to delete screen'))
        })
    })
  }
}

const mutations = {
  addScreenToStore (state, screenUuid) {
    state.screens.push(screenUuid)
    state.multipleScreens = state.screens.length > 1
  },
  setScreenList (state, screens) {
    state.screens = screens
    state.multipleScreens = state.screens.length > 1
  },
  removeScreenFromStore (state, screenUuid) {
    for (let i = 0; i < state.screens.length; i++) {
      if (state.screens[i].uuid === screenUuid) {
        state.screens.splice(i, 1)
      }
    }
  },
  clearStore (state) {
    Object.assign(state, getDefaultState())
  }
}

export const screens = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
}
